import { template as template_dd3680cbf9c045ebb62ed0c8415b42fc } from "@ember/template-compiler";
const FKLabel = template_dd3680cbf9c045ebb62ed0c8415b42fc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
