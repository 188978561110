import { template as template_1d20a86e99104b22b1239312824f287a } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_1d20a86e99104b22b1239312824f287a(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
