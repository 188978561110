import { template as template_ad3db72e4c0345a59a9effc8ab51f58f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_ad3db72e4c0345a59a9effc8ab51f58f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
