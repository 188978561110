import { template as template_73b802b859964592811d36a9e9f44873 } from "@ember/template-compiler";
const SidebarSectionMessage = template_73b802b859964592811d36a9e9f44873(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
