import { template as template_21fbe7bbade44d2bbe5225cdde1824d0 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_21fbe7bbade44d2bbe5225cdde1824d0(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
