import { template as template_3b83462e635c421ea631e479b0f0c2d3 } from "@ember/template-compiler";
const WelcomeHeader = template_3b83462e635c421ea631e479b0f0c2d3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
